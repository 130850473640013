import React from 'react'
import { getAccount } from "../../actions/accountAction";
class AccountDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loader: true,
            user: null
        };
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            search: this.props.record.accountCode
        };
        this.getAccountInfo(reqData);
      }
    
      async getAccountInfo(reqData) {
        try {
          this.setState({ loader: true });
          const { status, loading, result } = await getAccount(reqData);
          this.setState({ loader: loading });
          if (status == "success") {
            if(result.data.length){
                this.setState({
                    user: result.data[0]
                })
            }
          }
        } catch (err) { }
      }

    render() {
        const { record, viewRecord } = this.props;
        const user = this.state.user
        return (
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        {
                            record && record.transferIn && record.transferIn.medium != 'bank' ?
                                <div class="card-body">
                                    <h5 class="card-title">Transfer In</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record.transferIn.medium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record.transferIn.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Amount</span>
                                            <span className='detail'>{record.transferIn.currency}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Blockchain Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferIn?.details?.address}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="card-body">
                                    <h5 class="card-title">Transfer In</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium : {record?.transferIn?.medium.toUpperCase()}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Amount : {record?.transferIn?.amount} {record?.transferIn?.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Bank Name : {record?.transferIn?.details?.bank?.name}</span>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        {
                            record && record.transferIn && record.transferIn.medium != 'bank' ?
                                <div class="card-body">
                                    <h5 class="card-title">Transfer Out</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record?.outMedium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferOut?.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Receive Amount</span>
                                            <span className='detail'>{record?.transferOut?.receiveAmount}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Send Amount</span>
                                            <span className='detail'>{record?.transferOut?.sendAmount}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Account Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Number</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.number}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Bic</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.bic}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.currency}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Bank Details</p>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferOut?.details?.bank?.address}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Holder Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>City</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.city}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.country}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>State</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.state}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.zipcode}</span>
                                        </div>
                                        {
                                            record?.state == 'completed' && <div className='d-flex mb-1'>
                                                <span className='title'>Trx ID</span>
                                                <span className='detail'>{record?.transferOut?.trxId}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div class="card-body">
                                    <h5 class="card-title">Transfer Out</h5>
                                    <div className='trade-details'>

                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium : {record?.transferOut?.medium.toUpperCase()}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Amount : {record?.transferOut?.amount} {record?.transferOut?.currency}</span>
                                        </div>
                                        <div className='mb-1'>
                                            <span className='title'>Address : {record?.transferOut?.details?.address}</span>
                                        </div>
                                        {
                                            record?.state == 'completed' && <div className='mb-1'>
                                                <span className='title'>Trx ID : {record?.transferOut?.trxId}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div class='col-md-12'>
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">User Details</h5>
                            {
                                this.state.loader ? 
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                :
                                <div className='trade-details'>
                                    <div className='d-flex mb-1'>
                                        <span className='title'>Name:</span>
                                        <span className='detail pl-1'>{user?.lastName} {user?.firstName} </span>
                                    </div>
                                    <div className='d-flex mb-1'>
                                        <span className='title'>Email Address:</span>
                                        <span className='detail pl-1'>{user?.email}</span>
                                    </div>
                                    <div className='d-flex mb-1'>
                                        <span className='title'>Country: </span>
                                        <span className='detail pl-1'>{user?.country}</span>
                                    </div>
                                    <div className='d-flex mb-1'>
                                        <span className='title'>KYC status:</span>
                                        <span className='detail pl-1'>{user?.identificationState}</span>
                                    </div>
                                    <div className='d-flex mb-1'>
                                        <span className='title'>Declaration status:</span>
                                        <span className='detail pl-1'>{user?.declarationState}</span>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                </div>
                {
                    viewRecord && viewRecord?.transferDetails && viewRecord?.state == 'completed' &&
                        <div class='col-md-12'>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <h5 class="card-title">Transfer Details</h5>
                                    {
                                        this.state.loader ? 
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div className='trade-details'>
                                            <div className='d-flex mb-1'>
                                                <span className='title'>EURO Received:</span>
                                                <span className='detail pl-1'>€{isNaN(viewRecord?.transferDetails?.euroReceived) ? viewRecord?.transferDetails?.euroReceived : parseFloat(viewRecord?.transferDetails?.euroReceived).toLocaleString()}</span>
                                            </div>
                                            <div className='d-flex mb-1'>
                                                <span className='title'>Megixo Fees:</span>
                                                <span className='detail pl-1'>€{isNaN(viewRecord?.transferDetails?.megixoFee) ? viewRecord?.transferDetails?.megixoFee : parseFloat(viewRecord?.transferDetails?.megixoFee).toLocaleString()}</span>
                                            </div>
                                            <div className='d-flex mb-1'>
                                                <span className='title'>Exchange Rate:</span>
                                                <span className='detail pl-1'>{viewRecord?.transferDetails?.exchangeRate} EUR/{record?.transferOut?.currency}</span>
                                            </div>
                                            <div className='d-flex mb-1'>
                                                <span className='title'>Crypto Transfered:</span>
                                                <span className='detail pl-1'>{viewRecord?.transferDetails?.cryptoReceived} {record?.transferOut?.currency}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default AccountDetails;