import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import { withRouter } from 'react-router-dom';
//import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import UserAssetModal from "../partials/UserAssetModal";
//impport action
import {
  getPartner,
} from "../../actions/partnerAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { momentFormat } from "../../lib/dateTimeHelper";
import { toastAlert } from "../../lib/toastAlert";
class Users extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        width: 120,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: false,
      },
      {
        key: "partnerCode",
        text: "Partner Code",
        className: "partnerCode",
        align: "left",
        sortable: false,
      },
      // {
      //   key: "status",
      //   text: "Action",
      //   className: "status",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return (
      //       <>
      //         <button
      //           className={
      //             record.status === "verified"
      //               ? "btn btn-success btn-sm"
      //               : "btn btn-danger btn-sm"
      //           }
      //           onClick={() => this.LockedUser(record._id)}
      //         >
      //           {" "}
      //           {record.status === "verified" ? (
      //             <i class="fa fa-unlock " aria-hidden="true"></i>
      //           ) : (
      //             <i class="fa fa-lock" aria-hidden="true"></i>
      //           )}
      //         </button>
      //       </>
      //     );
      //   },
      // },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Contracts found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      userId: "",
    };

    this.getPartnersList = this.getPartnersList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getPartnersList(reqData);
  }
  async getPartnersList(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getPartner(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({
          count: result.count,
          records: result.data,
          loader: false,
        });
      }
    } catch (err) { }
  }
  handlePagination(index) {
    const { page, limit } = this.state;

    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getPartnersList(reqData);

    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const {
      count,
      loader,
      records,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Partner List</h3>

              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  responsive={true}
                  config={this.config}
                  loading={loader}
                  records={records}
                  total_record={count}
                  dynamic={true}
                  columns={this.columns}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Users);
