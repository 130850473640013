import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import { Link } from "react-router-dom";
import keys from "../../actions/config";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import adminLogo from "../../images/adminLogo2.png";
import Notification from '../pages/notification'
// import Logo from "../../Logo-small.png"
const url = keys.baseUrl;
class Navbar extends Component {

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
        window.location.reload();

    };
    
    render() {
        const { user } = this.props.auth;
        console.log(this.props);
        return (
            <div className="container-fluid p-0">

                <nav className="navbar navbar-expand-lg navbar-dark bg-dark adminNav">
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faList}/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    
                        <ul className="navbar-nav ml-auto">
                            {/* <Notification /> */}
                        {/* <li className="nav-item dropdown">
                                <a class="nav-link notify_icon" type="button" id="notifydropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-bell"></i><span className="notify_count">10</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right settings_dropmenu notification-dropdown" aria-labelledby="notifydropdownMenuButton">
                                <Scrollbars style={{ width: "100%", height: 375 }}>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>     
                                    </Scrollbars>                               
                                </div>                                
                            </li> */}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="settings"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Settings
                                </a>
                                <div className="dropdown-menu" aria-labelledby="settings">
                                     <Link to="/profile">
                                       Profile
                                     </Link>
                                    {/* <Link to="/changepassword">
                                       Change Password
                                     </Link> */}
                                     {/* <Link to="/settings">
                                       Settings
                                     </Link> */}
                                     <Link to="/login-history">
                                       Login History
                                     </Link>
                                     {/* <Link to="/security">
                                       Google 2FA
                                     </Link> */}

                                

                                </div>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/login"  onClick={this.onLogoutClick}>Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} /> </a>
                            </li>
                        </ul>
                       
                    </div>
                </nav>
            </div>
        );
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Navbar);
