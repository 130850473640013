import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import React, { Component } from 'react';
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";

import store from "./store";

// import component
import ConditionRoute from './components/Route/ConditionRoute';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';

// import config
import config from './config'

//routes
import Routers from './components/partials/routes'

// lib
import { getAuthToken } from './lib/localStorage';
import isEmpty from './lib/isEmpty'

// import constant
import {
    SET_AUTHENTICATION
} from './constant';

class App extends Component {

    constructor() {
        super()
    }

    async componentDidMount() {
        let token = getAuthToken();
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt_decode(token);
            if (decoded) {
                store.dispatch({
                    type: SET_AUTHENTICATION,
                    authData: {
                        isAuth: true,
                        userId: decoded._id,
                        restriction: decoded.restriction,
                        role: decoded.role
                    }
                })
            }
        }
    }


    render() {
        let records = store.getState()
        let restriction = records.isRun.restriction
        return (
            <Provider store={store}>
                <ToastContainer />
                <Router basename={config.BASE_NAME}>
                    <div className="App">
                        <Switch>
                            <Switch>
                                {
                                    Routers && Routers.length > 0 && Routers.map((route, idx) => {

                                        if (restriction && restriction.length > 0 && restriction.includes(route.path)) {
                                            return (

                                                <ConditionRoute
                                                    key={idx}
                                                    exact={route.exact}
                                                    path={route.path}
                                                    component={route.component}
                                                    type={route.type}
                                                    render={(props) => (
                                                        <>
                                                            <route.component {...props} />
                                                        </>
                                                    )}
                                                />
                                            )

                                        } else if (isEmpty(restriction)) {
                                            return (

                                                <ConditionRoute
                                                    key={idx}
                                                    exact={route.exact}
                                                    path={route.path}
                                                    component={route.component}
                                                    type={route.type}
                                                    render={(props) => (
                                                        <>
                                                            <route.component {...props} />
                                                        </>
                                                    )}
                                                />
                                            )

                                        }

                                    })
                                }
                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}


export default App
