// import config
import axios from "../config/axios";

export const getTrade = async (reqData) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/admin/trade`,
      params: reqData,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const changeState = async (reqData) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/admin/tradeState`,
      data: reqData,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const trxInState = async (reqData) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/admin/trxInState`,
      data: reqData,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};
