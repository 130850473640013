import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import lib
import { toastAlert } from "../../lib/toastAlert";

// import action
import { addCountry } from "../../actions/country";

const initialFormValue = {
  name: "",
  countryCode: "",
};

class CurrencyAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      formValue: initialFormValue,
      errors: {},
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, [name]: value };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleFile = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      countryCode,
    } = this.state.formValue;
    const { fetchData } = this.props;

    const reqData = {
      name,
      countryCode,
    };
    this.setState({ loader: true });
    try {
      const { status, loading, message, error } = await addCountry(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "currencyAddModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "currencyAddModal");
        }
      }
    } catch (err) { }
  };

  render() {
    const {
      name,
      countryCode,
    } = this.state.formValue;
    const { errors, loader } = this.state;

    const { isShow } = this.props;
    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Add Country</h4>
          </Modal.Header>
          <Modal.Body>
            <form noValidate>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Country Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="name"
                    type="text"
                    value={name}
                    onChange={this.handleChange}
                    error={errors.name}
                    className={classnames("form-control", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Country Code</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="countryCode"
                    type="text"
                    value={countryCode}
                    onChange={this.handleChange}
                    error={errors.countryCode}
                    className={classnames("form-control", {
                      invalid: errors.countryCode,
                    })}
                  />
                  <span className="text-danger">{errors.countryCode}</span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}Add currency
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CurrencyAddModal;
