import axios from 'axios'

export const bonusUpdate = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/bonus-update',
            method: 'post',
            data
        })
        return {
            status: 'success',
            message: respData.data.message,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}
export const addPromo = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/add-promo',
            method: 'post',
            data
        })
        return {
            status: 'success',
            message: respData.data.message,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}
export const getPromo = async () => {
    try {
        const respData = await axios({
            url: `/adminapi/get-promo`,
            method: "get",
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};