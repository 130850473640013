// import config
import axios from "../config/axios";

export const getAccount = async (reqData) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/account/all`,
      params: reqData,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getDeclaration = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: "/api/admin/declaration",
      params: data
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.declaration,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getUserDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: "/api/admin/details",
      params: data
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    console.log(err);
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const verifyDeclaration = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: "/api/admin/declaration",
      data
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.declaration,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const sentKycMail = async (reqData) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/account/sentKycMail`,
      data: reqData,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const updatePOA = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/account/updatePOA`,
      data
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const updatePOT = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/account/updatePOT`,
      data
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};