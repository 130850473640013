import React, { Component } from "react";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import SiteDetails from "../partials/siteDetals";

// import action
import {
  getSiteSetting,
} from "../../actions/siteSettingActions";

//lib

class SiteSettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: {},
      loader: false,
      errors: {},
    };

    this.fetchSiteSetting = this.fetchSiteSetting.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchSiteSetting();
  }

  async fetchSiteSetting() {
    try {
      const { status, loading, result } = await getSiteSetting();
      if (status == "success") {
        this.setState({ loader: false });
        this.setState({ records: result });
      }
    } catch (err) { }
  }

  render() {
    const {
      records,
      loader,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <SiteDetails
                records={records}
                fetchSiteSetting={this.fetchSiteSetting}
                loader={loader}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SiteSettingPage;
