import React, { useState } from 'react'
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";



//import action
import { addPromo } from '../../actions/bonusAction'

//import lib
import { toastAlert } from '../../lib/toastAlert';

let initialFormValue = {
    percentage: '',
    type: '',
    code: '',
    expiryDate: ''
}
class promoAddModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            formValue: initialFormValue,
            errors: {},
        };
    }
  
    handleClose = () => {
        let { onHide } = this.props
        this.setState({ errors: {} })
        this.setState({ formValue: initialFormValue })
        this.setCode()
        onHide()
    }
    handleDateChange = (date) => {
        let formData = { ...this.state.formValue, ...{ expiryDate: date } }
        this.setState({ formValue: formData })
        if (date) {
            this.setState({ errors: {} })
        }
    }
    handleChange = (e) => {
        let { name, value } = e.target
        let formData = { ...this.state.formValue, ...{ [name]: value } }
        this.setState({ formValue: formData })
        if (value) {
            this.setState({ errors: {} })
        }
    }
    handleClick = (e) => {
        let { name, value } = e.target
        let formData = { ...this.state.formValue, ...{ [name]: value } }
        this.setState({ formValue: formData })
        if (value) {
            this.setState({ errors: {} })
        }
    }
    handleSubmit = async () => {
        let { percentage, type, code, expiryDate } = this.state.formValue
        let data = {
            percentage,
            type,
            code,
            expiryDate
        }

        let { status, message, errors } = await addPromo(data)
        if (status == 'success') {
            let { fetch } = this.props

            toastAlert('success', message, 'promo code')
            this.handleClose()
            fetch()
            this.setCode()
            this.setState({ errors: {} })

        } else {
            toastAlert('error', message, 'promo code')
            this.setState({ errors: errors })
        }
    }
    setCode = () => {
        var result = '';
        function makeid(length) {
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789342353456464575675685687568769769';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
        }
        makeid(16);
        let formData = { ...this.state.formValue, ...{ code: result } }
        this.setState({ formValue: formData })
    }
    componentWillMount() {
        this.setCode()
    }

    render() {
        let { isShow } = this.props
        let { percentage, type, code, expiryDate } = this.state.formValue
        let { errors } = this.state
        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Add Promo Code</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="taker_fees">code</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    // onChange={this.handleChange}
                                    value={code}
                                    name="firstFloatDigit"
                                    id="firstFloatDigit"
                                    type="text"
                                    className="form-control"
                                    disabled
                                />
                                <span className="text-danger">{errors && errors.code}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="taker_fees">percentage</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    onChange={this.handleChange}
                                    value={percentage}
                                    name="percentage"
                                    id="firstFloatDigit"
                                    type="text"
                                    className="form-control"
                                />
                                <span className="text-danger">{errors && errors.percentage}</span>
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="taker_fees">expiryDate</label>
                            </div>
                            <div className="col-md-9">
                                <DatePicker
                                    selected={expiryDate}
                                    onChange={(date) => this.handleDateChange(date)}
                                    placeholderText="Choose a date"
                                />
                                <span className="text-danger">{errors && errors.expiryDate}</span>
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-md-3">

                                <label htmlFor="currencyName" >type</label>
                            </div>
                            <div className="col-md-9">
                                <div class="form-check form-check-inline">
                                    <input
                                        default={type}
                                        class="form-check-input"
                                        type="radio"
                                        name="type"
                                        value="single"
                                        onClick={this.handleClick}
                                        checked={type == 'single'}
                                    />
                                    <label class="form-check-label" for="inlineRadio1"><b>single</b></label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        default={type}
                                        class="form-check-input"
                                        type="radio"
                                        name="type"
                                        value="multi"
                                        onClick={this.handleClick}
                                        checked={type == 'multi'}
                                    />
                                    <label class="form-check-label" for="inlineRadio2"><b>multi</b></label>
                                </div>
                                <span className="text-danger">{errors && errors.type}</span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default promoAddModal;
