import React, { Component } from "react";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import BankDetails from "../partials/BankDetails";

// import action
import {
  getBankDetils,
} from "../../actions/adminBankAccount";
class BankAccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: {},
      loader: false,
      errors: {},
    };

    this.fetchBankSetting = this.fetchBankSetting.bind(this);
  }

  componentDidMount() {
    this.fetchBankSetting();
  }

  async fetchBankSetting() {
    try {
      const { status, loading, result } = await getBankDetils();
      if (status == "success") {
        this.setState({ loader: false, records: result });
      }
    } catch (err) { }
  }

  render() {
    const {
      records,
      loader,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <BankDetails
                records={records}
                fetchBankSetting={this.fetchBankSetting}
                loader={loader}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankAccountSetting;
