// import config
import axios from '../config/axios';

export const getCurrency = async (reqData) => {
    try {
        const respData = await axios({
            'url': '/api/currency',
            'method': 'get',
            'params': reqData
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const addCurrency = async (reqData) => {
    try {
        const respData = await axios({
            'url': '/api/currency',
            'method': 'post',
            'data': reqData
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const updateCurrency = async (reqData) => {
    try {
        const respData = await axios({
            'url': `/api/currency`,
            'method': 'put',
            'data': reqData
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}