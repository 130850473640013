// import config
import axios from '../config/axios';

export const getCopyTradeSettings = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/cpFuture/setting`
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const updateCopyTradeSettings = async (data) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/adminapi/cpFuture/setting`,
            data
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        };
    }
};

export const traderList = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/adminapi/cpFuture/trader`,
            params: data
        });
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const traderStatus = async (data) => {
    try {
        let respData = await axios({
            method: "patch",
            url: `/adminapi/cpFuture/trader`,
            data
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message
        };
    }
};

export const getPLHistory = async (data = {}) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/getPLHistory`,
            data
        });
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getTopTraders = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/adminapi/cpFuture/trader`,
            'params': data
        });
        console.log('respData.data---', respData.data.result)
        return {
            status: "success",
            loading: false,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

