import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//import lib
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { toastAlert } from "../../lib/toastAlert";
import { dateTimeFormat } from "../../lib/dateTimeHelper";
//import action
import { bonusUpdate, getPromo, addPromo } from "../../actions/bonusAction";

//import modal
import PromoAddModal from "../partials/PromoAddModal";

let initialValue = {
  percentage: "",
  tradeVolume: "",
};
const PromoCode = () => {
  const [valid, setValid] = useState({});
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [formValue, setFormValue] = useState(initialValue);
  const { percentage, tradeVolume, type } = formValue;
  const columns = [
    {
      key: "code",
      text: "code",
      className: "description",
      align: "left",
      sortable: true,
      // width: 140
    },
    {
      key: "type",
      text: "Type",
      className: "description",
      align: "left",
      sortable: true,
    },
    {
      key: "value",
      text: "Percentage",
      className: "description",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record && <p> {record.value}%</p>;
      },
    },
    {
      key: "expiryDate",
      text: "Expiry Date",
      className: "description",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record && dateTimeFormat(record.expiryDate, "YYYY-MM-DD");
      },
    },
    {
      key: "expiryDate",
      text: "Action",
      className: "description",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(record._id)}
          >
            {" "}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (value) {
      setValid({});
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    let data = {
      percentage,
      tradeVolume,
      type,
    };
    let { status, errors, message } = await bonusUpdate(data);
    setLoader(true);
    if (status == "success") {
      setLoader(false);
      toastAlert("success", message, "update bonus");
    } else {
      setLoader(false);
      toastAlert("error", message, "update bonus");
      setValid(errors);
    }
  };
  const handleModal = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = async (e) => {
    let data = {
      id: e,
      type: "remove",
    };
    setLoader(true);
    let { status, message } = await addPromo(data);
    if (status == "success") {
      setLoader(false);
      toastAlert("success", message, "remove bonus");
      fetchList();
    }
  };
  const fetchList = async () => {
    let { status, result } = await getPromo();
    setLoader(true);
    if (status == "success") {
      setLoader(false);
      let formData = {
        percentage: result[0].value,
        tradeVolume: result[0].tradeVol,
      };
      setFormValue({ ...formValue, ...formData });
      setData(result[0].promo);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h3 className="mt-2 text-secondary"> Bonus Settings </h3>
            <Card>
              <Card.Header>
                <b>First Deposit</b>
              </Card.Header>
              <Card.Body>
                {loader === true ? (
                  <p>Loading ...</p>
                ) : (
                  <>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">percentage</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          value={percentage}
                          onChange={handleChange}
                          name="percentage"
                          // error={errors.twiterLink}
                          type="text"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {valid && valid.percentage}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">trade volume</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          value={tradeVolume}
                          onChange={handleChange}
                          name="tradeVolume"
                          // error={errors.twiterLink}
                          type="text"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {valid && valid.tradeVolume}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
              <Card.Footer>
                <Button onClick={handleClick}>Submit</Button>
              </Card.Footer>
            </Card>
          </div>
          <div className="container-fluid">
            <Card>
              <Card.Header>
                <b>Promo Code</b>
                <button
                  onClick={handleModal}
                  className="btn btn-outline-primary float-right mt-3 mr-2"
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Promo Code
                </button>
              </Card.Header>
              <Card.Body>
                <PromoAddModal
                  isShow={show}
                  onHide={handleClose}
                  fetch={fetchList}
                />

                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                  loading={loader}
                  // onPageChange={this.pageChange.bind(this)}
                />
              </Card.Body>
              <Card.Footer>{/* <Button >Submit</Button> */}</Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
