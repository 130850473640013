import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { withRouter } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";

//import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//impport action
import {
  getAccount,
  getDeclaration,
  verifyDeclaration,
  sentKycMail,
  updatePOA,
  updatePOT
} from "../../actions/accountAction";

// import lib
import { momentFormat } from "../../lib/dateTimeHelper";
import { capitalize } from '../../lib/capitalize'
import { toastAlert } from '../../lib/toastAlert'
import isEmpty from "is-empty";

import { pdf } from '@react-pdf/renderer';

import {generatePDFContent}  from "../DeclarationPDF";



class Users extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        width: 150,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "accountCode",
        text: "Account Code",
        className: "accountCode",
        align: "left",
        sortable: false,
        width: 150,
        cell: (record) => {
          return (
            <Fragment>
              <span>{record.accountCode}</span>
              {
                record.identificationState == "approved" && record.declarationState == "approved" && !isEmpty(record?.proofOfAddress) && !isEmpty(record.proofOfTransfer) &&
                <i class="fa-solid fa-circle-check text-success pl-1"></i>
              }
            </Fragment>
          )
        }
      },
      {
        key: "firstName",
        text: "Name",
        className: "name",
        align: "left",
        sortable: false,
        cell: (record) =>{ return ( (record.lastName? record.lastName : "" ) +" "+ (record.firstName? record.firstName: "")) }
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: false,
        width: 300,
      },
      {
        key: "country",
        text: "Country",
        className: "country",
        align: "left",
        sortable: false
      },
      {
        key: "declarationState",
        text: "Declaration State",
        className: "declarationState",
        align: "left",
        sortable: false,
        width: 175,
        cell: (record) => {
          return (
            <Fragment>
              <div>
                {record.declarationState == "processing" && <i class="fa-solid fa-circle-question text-warning pl-1" onClick={() => {this.getDeclarationData(record._id, record.declarationState)}}></i>} 
                {record.declarationState == "approved" && <i class="fa-solid fa-circle-check text-success pl-1" onClick={() => {this.getDeclarationData(record._id, record.declarationState)}}></i>} 
                {record.declarationState == "rejected" && <i class="fa-sharp fa-solid fa-circle-xmark text-danger pl-1" onClick={() => {this.getDeclarationData(record._id, record.declarationState)}}></i>} 
              </div>
            </Fragment>
          );
        },
      },
      {
        key: "identificationState",
        text: "KYC State",
        className: "declarationState",
        align: "left",
        sortable: false,
        width: 150,
        cell: (record) => {
          return(
          <Fragment>
            <div>
              {record.identificationState == "approved" && <i class="fa-solid fa-circle-check text-success pl-1"></i>} 
              {record.identificationState == "rejected" && <i class="fa-sharp fa-solid fa-circle-xmark text-danger pl-1"></i>} 
              {record.identificationState == "pending" && <i class="fa-solid fa-circle-question text-warning pl-1"></i>} 
              {/* {record.identificationState != "approved" && <i class="fas fa-paper-plane" onClick={() => this.sentMail(record)}></i> } */}
            </div>
          </Fragment>
          )
        },
      },
      {
        key: "proofOfAddressData",
        text: "POA",
        className: "declarationState",
        align: "left",
        sortable: false,
        width: 150,
        cell: (record) => {
          return(
          <Fragment>
            <div className={!isEmpty(record?.proofOfAddressData) && "text-success"}>
              <i class="fa fa-lg fa-upload pr-3" onClick={() => {this.setState({ modalAddressShow: true, recordData: record })}}></i>
              { 
                !isEmpty(record.proofOfAddress) && 
                <>
                  <i class="fa-lg fa-solid fa-book position-relative" onClick={() => {this.setState({ modalAddressListShow: true, recordData: record })}}>
                    <div className="docCount">{record.proofOfAddress.length}</div>
                  </i>
                </>
              }
            </div>
          </Fragment>
          )
        },
      },
      {
        key: "proofOfTransferData",
        text: "POT",
        className: "declarationState",
        align: "left",
        sortable: false,
        width: 150,
        cell: (record) => {
          return(
          <Fragment>
            <div className={!isEmpty(record?.proofOfTransferData) && "text-success"}>
              <i class="fa fa-lg fa-upload pr-3" onClick={() => {this.setState({ modalTransferShow: true, recordData: record })}}></i>
              { 
                !isEmpty(record.proofOfTransfer) && 
                <>
                  <i class="fa-lg fa-solid fa-book position-relative" onClick={() => {this.setState({ modalTransferListShow: true, recordData: record })}}>
                    <div className="docCount">{record.proofOfTransfer.length}</div>
                  </i>
                </>
              }
            </div>
          </Fragment>
          )
        },
      }
    ];

    this.docColumns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "name",
        text: "Document Name",
        className: "name",
        align: "left",
        sortable: false,
      },
      {
        key: "fileName",
        text: "Document",
        className: "fileName",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {<a href={this.state.docLocation + "/" + record.fileName} target="_blank">Preview</a>}
            </Fragment>
          );
        },
      },
    ];

    this.docConfig = {
      no_data_text: "No Contracts found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: false,
      show_filter: false,
      show_pagination: false,
      show_info: false,
    }

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Contracts found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      recordData: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      userId: "",
      modalshow: false,
      modalAddressShow: false,
      modalAddressListShow: false,
      modalTransferShow: false,
      modalTransferListShow: false,
      declarationState: "",
      declarationData: {},
      proofOfAddressData: {},
      proofOfTransferData: {},
      POTName: "",
      POTFile: "",
      docLocation: ""
    };

    this.getDeclarationData = this.getDeclarationData.bind(this)
    this.getAccountList = this.getAccountList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getAccountList(reqData);
  }
  async getAccountList(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getAccount(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({
          count: result.count,
          records: result.data,
          loader: false,
          docLocation: result.docLocation
        });
      }
    } catch (err) { }
  }
  

  async getDeclarationData(reqData, state) {
    try {
      let data = {
        id: reqData
      }

      const { status, result } = await getDeclaration(data);
      if (status == "success") {
        this.setState({
          declarationData: result,
          modalshow: true,
          declarationState: state 
        })
      }
    } catch (err) {}
  }

  async sentMail(record) {
    try {
      let reqData = {
        'accountId': record._id
      }
      const { status, message } = await sentKycMail(reqData)
      if (status == 'success') {
        toastAlert('success', message, 'account')
      }
    } catch (err) {
    }
  }

  handlePagination(index) {
    const { page, limit } = this.state;

    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getAccountList(reqData);

    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  handleFile = async (e) => {
    const { name, files } = e.target;
    let checkName = files[0].name.split('.');

    if(!(checkName[checkName.length - 1] == 'png' ||
         checkName[checkName.length - 1] == 'jpg' ||
         checkName[checkName.length - 1] == 'jpeg' ||
         checkName[checkName.length - 1] == 'pdf')) {
        toastAlert('error', "Wrong file selected, please try again", 'changeDoc');
        return;
    }
    
    this.setState({[name]: files[0]})
  }

  handlePOAUpdate = async () => {
    if(isEmpty(this.state.POTFile) || isEmpty(this.state.POTName)) {
      toastAlert('error', "File missing, please upload a document before submitting", 'changeDoc')
      return
    }

    try {
      const formData = new FormData()
      formData.append('id', this.state.recordData._id)
      formData.append('documentImage', this.state.POTFile)
      formData.append('name', this.state.POTName)

      const { status, result, loading, message } = await updatePOA(formData)
      if(status) {
        let reqData = {
          page: this.state.page,
          limit: this.state.limit,
        }
        this.getAccountList(reqData)
        this.modalAddressClose()
        this.setState({POTFile:"", POTName:""});
      }
    } catch(err) {
      console.log("error message", err.message)
    }
  }

  handlePOTUpdate = async () => {
    if(isEmpty(this.state.POTFile) || isEmpty(this.state.POTName)) {
      toastAlert('error', "File missing, please upload a document before submitting", 'changeDoc')
      return
    }

    try {
      const formData = new FormData()
      formData.append('id', this.state.recordData._id)
      formData.append('documentImage', this.state.POTFile)
      formData.append('name', this.state.POTName)

      const { status, result, loading, message } = await updatePOT(formData)
      if(status == "success") {
        let reqData = {
          page: this.state.page,
          limit: this.state.limit,
        }
        this.getAccountList(reqData)
        this.modalTransferClose()
        this.setState({POTFile:"", POTName:""});
      }
    } catch(err) { }
  }

  modalclose = () => {
    this.setState({modalshow:false});
  }

  modalTransferClose = () => {
    this.setState({modalTransferShow:false,docName:""});
    this.setState({POTFile:"", POTName:""});
  }

  modalTransferListClose = () => {
    this.setState({modalTransferListShow:false});
  }
  
  modalAddressClose = () => {
    this.setState({modalAddressShow:false});
    this.setState({POTFile:"", POTName:""});
  }

  modalAddressListClose = () => {
    this.setState({modalAddressListShow:false});
  }

  async handleDeclarationVerification(reqData, opt) {
    try {
      let data = {
        id: reqData,
        state: opt
      }

      const { status } = await verifyDeclaration(data);
      if (status == "success") {
        const { page, limit } = this.state;
        let reqData = {
          page,
          limit,
        };
        this.getAccountList(reqData);
        this.modalclose()
      }
    } catch (err) {}
  }

  generateAndDownloadPDF = async () => {

    try{
      const pdfContent = await generatePDFContent(this.state.declarationData?.accountId);
      const blob = await pdf(pdfContent).toBlob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =  `${this.state.declarationData?.accountCode}.pdf`;
      document.body.appendChild(a);
    
      // Trigger the click event to start the download
      a.click();
    
      // Clean up
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }catch(err){
      console.log(err)
    }


  };
  render() {
    const {
      count,
      loader,
      records,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Account List</h3>

              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  responsive={true}
                  config={this.config}
                  loading={loader}
                  records={records}
                  total_record={count}
                  dynamic={true}
                  columns={this.columns}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.modalshow} onHide={this.modalclose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
              <Modal.Title>Questionnaire answers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.state.declarationData && !isEmpty(this.state.declarationData) > 0 &&
              <>
              
              <h3 className="pb-0">1. Účel a plánovaná povaha transakcií vykonávaných na Platforme a finančné informácie</h3>
              <div>
                Q: Účel a plánovaná povaha transakcií vykonávaných na Platforme je:
              </div>
              <strong>
                A: { this.state.declarationData.purposeOfTransactions }
              </strong>
              <hr/>

              <div>
                Q: Predpokladaný celkový objem transakcií počas obdobia jedného mesiaca na Platforme je:
              </div>
              <strong>
                A: { this.state.declarationData.estimatedTotalVolumeofTransactions }
              </strong>
              <hr/>

              <div>
                Q: Predpokladaná časová frekvencia vykonávania transakcií na Platforme je:
              </div>
              <strong>
                A: { this.state.declarationData.freqOfTransactions }
              </strong>
              <hr/>

              <div>
                Q: Môj čistý ročný príjem je:
              </div>
              <strong>
                A: { this.state.declarationData.netAnnualIncome }
              </strong>
              <hr/>
              <br/>

              <h3>2. Konečný užívateľ výhod fyzickej osoby – podnikateľa</h3>
              <div>
                Q: som konečným užívateľom výhod:
              </div>
              <strong>
                A: { this.state.declarationData.isEndUser.toString() }
              </strong>
              {
                !this.state.declarationData.isEndUser && 
                <>
                  <hr/>
                  <div className="row">
                    <div className="col-12 pb-2">
                      Konečným užívateľom výhod (fyzická osoba, ktorá má právo na hospodársky prospech najmenej 25 % z podnikania fyzickej osoby – podnikateľa alebo z inej jej činnosti) je:
                    </div>                  
                    <div className="col-6">
                      Meno a priezvisko:
                    </div>
                    <strong className="col-6">
                      { this.state.declarationData.otherEndUser.fullName }
                    </strong>
                    <div className="col-6">
                      IČO / Dátum narodenia a rodné číslo:
                    </div>
                    <strong className="col-6">
                      { this.state.declarationData.otherEndUser.birthNumber }
                    </strong>
                    <div className="col-6">
                      Adresa trvalého pobytu alebo iného pobytu:
                    </div>
                    <strong className="col-6">
                      { this.state.declarationData.otherEndUser.address }
                    </strong>
                    <div className="col-6">
                      Štátna príslušnosť:
                    </div>
                    <strong className="col-6">
                      { this.state.declarationData.otherEndUser.nationality }
                    </strong>
                  </div>
                </>
              }
              <hr/>
              <br/>

              <h3>3. Politicky exponovaná osoba</h3>
              <div>
                Q: som politicky exponovanou osobou v zmysle AML zákona:
              </div>
              <strong>
                A: { this.state.declarationData.isPEP.toString() }
              </strong>
              {
                this.state.declarationData.isPEP && 
                <>
                  <hr/>
                  <div className="row">
                    <div className="col-12">
                      Politicky exponovanou osobou v zmysle AML zákona som nasledovného dôvodu:
                    </div>
                    <div className="col-12">
                      { this.state.declarationData.PEPDetails && 
                          <>
                            <strong>
                              { this.state.declarationData.PEPDetails.option !== "other" && this.state.declarationData.PEPDetails.option} {" " + this.state.declarationData.PEPDetails.subOption}
                            </strong>
                            {
                              this.state.declarationData.PEPDetails.lessthan12M && 
                              <>
                                <hr/>
                                <div>
                                  bol som politicky exponovanou osobou pred menej ako 12 mesiacmi (v tomto prípade prosím zaškrtnite aj niektorú z možností uvedených vyššie odôvodňujúcich Vašu klasifikáciu ako politicky exponovanej osoby)
                                </div>
                                <strong>
                                  { this.state.declarationData.PEPDetails.lessthan12M.toString() }
                                </strong>
                              </>
                            }
                          </>
                      }
                    </div>
                  </div>
                </>
              }
              <hr/>
              <br/>

              <h3>4. Pôvod finančných prostriedkov</h3>
              <div>
                Q: finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu sú mojím vlastníctvom:
              </div>
              <strong>
                A: { this.state.declarationData.isFundsMine.toString() }
              </strong>
              {
                !this.state.declarationData.isFundsMine && 
                <div className="row">
                  <div className="col-12">
                    Finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu sú vo vlastníctve osoby:
                  </div>
                  <div className="col-6">
                    Obchodné meno / Meno a priezvisko:
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.fundsDetails.fullName }
                  </strong>
                  <div className="col-6">
                    IČO / Dátum narodenia a rodné číslo:
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.fundsDetails.birthNumber }
                  </strong>
                  <div className="col-6">
                    Miesto podnikania, resp. sídlo /Adresa trvalého pobytu:
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.fundsDetails.address }
                  </strong>
                </div>
              }
              <hr/>
              <div>
                Q: Pôvod finančných prostriedkov - Finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu pochádzajú z:
              </div>
              <strong>
                A: { this.state.declarationData.originOfFunds }
              </strong>
              <hr/>
              <br/>

              <h3>5. Konanie</h3>
              <div>
                Q: tento obchod vykonávam  vo vlastnom mene:
              </div>
              <strong>
                A: { this.state.declarationData.conductingBusinessMyself.toString() }
              </strong>
              {
                !this.state.declarationData.conductingBusinessMyself && 
                <div className="row">
                  <div className="col-12">
                    Tento obchod vykonávam v mene osoby:
                  </div>
                  <div className="col-6">
                    Obchodné meno / Meno a priezvisko: 
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.conductingBusinessDetails.fullName }
                  </strong>
                  <div className="col-6">
                    IČO / Dátum narodenia a rodné číslo:
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.conductingBusinessDetails.birthNumber }
                  </strong>
                  <div className="col-6">
                    Miesto podnikania, resp. sídlo /Adresa trvalého pobytu:
                  </div>
                  <strong className="col-6">
                    { this.state.declarationData.conductingBusinessDetails.address }
                  </strong>
                </div>
              }
              </>
            }

          </Modal.Body>
          <Modal.Footer>
               <Button variant="secondary btnDefaultNewBlue" onClick={this.generateAndDownloadPDF}>
              Download PDF
            </Button>
            <Button variant="secondary btnDefaultNewBlue" onClick={this.modalclose}>
              Zrušiť
            </Button>
            {
              this.state.declarationState != "rejected" &&
                <Button variant="danger btnDefaultNew" onClick={() => {this.handleDeclarationVerification(this.state.declarationData.accountId, "rejected")}}>
                    Odmietnuť
                </Button>
            }
            {
              this.state.declarationState != "approved" &&
              <Button variant="success btnDefaultNew" onClick={() => {this.handleDeclarationVerification(this.state.declarationData.accountId, "approved")}}>
                  Potvrdiť
              </Button>
            }            
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalAddressShow} onHide={this.modalAddressClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
              <Modal.Title>Proof of Address Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-2 mb-4">
              <div className="col-md-3">
                <label className="mt-1">Name of Document: </label>
              </div>
              <div class="col-md-9">
                <input name="POTName" value={this.state.POTName} type="text" class="form-control" onChange={(e) => this.setState({POTName: e.target.value})} />
                <span class="text-danger"></span>
              </div>
            </div>
            <label className="w-100 text-center" style={{ padding: "20px 40px", border: "2px dotted black" }} htmlFor="POTFile">
              <h5>
                {
                  typeof(this.state.POTFile) != "object" ? 
                      <>No File Uploaded</>
                    : this.state.POTFile?.name 
                }
              </h5>
              <input
                  type="file"
                  id="POTFile"
                  className="profileSection d-none"
                  aria-describedby="inputGroupFileAddon01"
                  name="POTFile"
                  onChange={this.handleFile}
                  accept=".png,.jpg,.jpeg,.pdf"
              />
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z" fill="#18A594"/>
              </svg>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary btnDefaultNewBlue" onClick={() => {this.handlePOAUpdate()}}>
              Upload
            </Button>    
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalTransferShow} onHide={this.modalTransferClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
              <Modal.Title>Proof of Transfer Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-2 mb-4">
              <div className="col-md-3">
                <label className="mt-1">Name of Document: </label>
              </div>
              <div class="col-md-9">
                <input name="POTName" value={this.state.POTName} type="text" class="form-control" onChange={(e) => this.setState({POTName: e.target.value})} />
                <span class="text-danger"></span>
              </div>
            </div>
            <label className="w-100 text-center" style={{ padding: "20px 40px", border: "2px dotted black" }} htmlFor="POTFile">
              <h5>
                {
                  typeof(this.state.POTFile) != "object" ? 
                      <>No File Uploaded</>
                    : this.state.POTFile?.name 
                }
              </h5>
              <input
                  type="file"
                  id="POTFile"
                  className="profileSection d-none"
                  aria-describedby="inputGroupFileAddon01"
                  name="POTFile"
                  onChange={this.handleFile}
                  accept=".png,.jpg,.jpeg,.pdf"
              />
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z" fill="#18A594"/>
              </svg>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary btnDefaultNewBlue" onClick={() => {this.handlePOTUpdate()}}>
              Upload
            </Button>    
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalTransferListShow} onHide={this.modalTransferListClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
              <Modal.Title>List - Proof of Transfer Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              !isEmpty(this.state.recordData) &&
                <ReactDatatable
                  responsive={true}
                  config={this.docConfig}
                  loading={loader}
                  records={this.state.recordData.proofOfTransfer.sort((a,b) => {return b.createdAt - a.createdAt})}
                  total_record={this.state.recordData.proofOfTransfer.length}
                  dynamic={true}
                  columns={this.docColumns}
                />
            }
          </Modal.Body>
        </Modal>

        <Modal show={this.state.modalAddressListShow} onHide={this.modalAddressListClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
              <Modal.Title>List - Proof of Address Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              !isEmpty(this.state.recordData) &&
                <ReactDatatable
                  responsive={true}
                  config={this.docConfig}
                  loading={loader}
                  records={this.state.recordData.proofOfAddress.sort((a,b) => {return b.createdAt - a.createdAt})}
                  total_record={this.state.recordData.proofOfAddress.length}
                  dynamic={true}
                  columns={this.docColumns}
                />
            }
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default withRouter(Users);
