// import config
import axios from "../config/axios";

export const getPartner = async (reqData) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/partner/all`,
      params: reqData,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};