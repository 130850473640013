import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer } from "react-toastify";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getBankAccount } from "../../actions/bankAccountAction";

//import downloads
import "jspdf-autotable";

//import lib
import { momentFormat } from "../../lib/dateTimeHelper";

class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        width: 120,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "accName",
        text: "Account Name",
        className: "accName",
        align: "left",
        sortable: false
      },
      {
        key: "currency",
        text: "Currency",
        className: "currency",
        align: "left",
        sortable: false
      },
      {
        key: "bic",
        text: "BIC",
        className: "bic",
        align: "left",
        sortable: false
      },
      {
        key: "number",
        text: "Number",
        className: "number",
        align: "left",
        sortable: false
      },
      {
        key: "bankCountry",
        text: "Country",
        className: "counbankCountrytry",
        align: "left",
        sortable: false
      },
      {
        key: "holderName",
        text: "Holder name",
        className: "holderName",
        align: "left",
        sortable: false
      },
      {
        key: "holderCountry",
        text: "Holder Country",
        className: "holderCountry",
        align: "left",
        sortable: false
      },
      {
        key: "holderState",
        text: "Holder State",
        className: "holderState",
        align: "left",
        sortable: false
      },
      {
        key: "holderCity",
        text: "Holder City",
        className: "holderCity",
        align: "left",
        sortable: false
      },
      {
        key: "holderZipcode",
        text: "Holder Zipcode",
        className: "holderZipcode",
        align: "left",
        sortable: false
      }
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Currency",
      no_data_text: "No Currency found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
      records: [],
    };

    this.getBankAccountList = this.getBankAccountList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.refetch = this.refetch.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getBankAccountList(reqData);
  }

  async getBankAccountList(reqData) {
    this.setState({ loader: true });
    try {
      const { status, loading, message, result } = await getBankAccount(reqData);
      this.setState({ loader: false });
      if (status == "success") {
        this.setState({
          count: result.count,
          records: result.data,
          imageUrl: result.imageUrl,
        });
      }
    } catch (err) { }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getBankAccountList(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  addRecord() {
    this.setState({ addFormModal: true });
  }

  editRecord(record) {
    this.setState({
      editFormModal: true,
      editRecord: record,
    });
  }

  handleCloseAddForm() {
    this.setState({ addFormModal: false });
  }

  handleCloseEditForm() {
    this.setState({ editFormModal: false, editRecord: {} });
  }

  refetch() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getBankAccountList(reqData);
  }

  render() {
    const {
      loader,
      count,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary"> Bank Account Management</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={count}
                loading={loader}
                onChange={this.handlePagination}
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
export default BankAccount;
