import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { updateCurrency } from "../../actions/currency";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  currencyId: "",
  name: "",
  coin: "",
  symbol: "",
  decimals: "",
  type: "crypto",
  fee: "",
  status: "active",
};

class CurrencyUpdateModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      formValue: initialFormValue,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record, imageUrl } = nextProps;
    if (record) {
      let formData = {
        currencyId: record._id,
        type: record.type,
        name: record.name,
        fee: record.fee,
        coin: record.coin,
        decimals : record.decimals,
        symbol: record.symbol,
        status: record.status,
      };
      this.setState({ formValue: formData });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formValue } = this.state;
    const { fetchData } = this.props;

    try {
      this.setState({ loader: true });
      const { status, loading, message, error } = await updateCurrency(this.state.formValue);
      this.setState({ loader: loading });
      if (status == "success") {
        this.handleClose();
        fetchData();
        toastAlert("success", message, "currencyUpdateModal");
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "currencyUpdateModal");
        }
      }
    } catch (err) { }
  };

  render() {
    const {
      type,
      name,
      coin,
      symbol,
      decimals,
      status,
      fee,
    } = this.state.formValue;
    const { errors, loader } = this.state;
    const { isShow } = this.props;

    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Update Currency</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Currency Type</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"crypto"}>Crypto</option>
                    <option value={"token"}>Token</option>
                    <option value={"fiat"}>Fiat</option>
                  </Form.Control>

                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Currency Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="name"
                    type="text"
                    value={name}
                    onChange={this.handleChange}
                    error={errors.name}
                    className={classnames("form-control", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Coin</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="coin"
                    type="text"
                    value={coin}
                    onChange={this.handleChange}
                    error={errors.coin}
                    className={classnames("form-control", {
                      invalid: errors.coin,
                    })}
                  />
                  <span className="text-danger">{errors.coin}</span>
                </div>
              </div>
              
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Decimals</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="decimals"
                    type="text"
                    value={decimals}
                    onChange={this.handleChange}
                    error={errors.decimals}
                    className={classnames("form-control", {
                      invalid: errors.decimals,
                    })}
                  />
                  <span className="text-danger">{errors.decimals}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Symbol</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="symbol"
                    type="text"
                    value={symbol}
                    onChange={this.handleChange}
                    error={errors.symbol}
                    className={classnames("form-control", {
                      invalid: errors.symbol,
                    })}
                  />
                  <span className="text-danger">{errors.symbol}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>fee</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="fee"
                    type="text"
                    value={fee}
                    onChange={this.handleChange}
                    error={errors.fee}
                    className={classnames("form-control", {
                      invalid: errors.fee,
                    })}
                  />
                  <span className="text-danger">{errors.fee}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Status</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    name="status"
                    value={status}
                    onChange={this.handleChange}
                    as="select"
                    custom
                  >
                    <option value={"active"}>Active</option>
                    <option value={"deactive"}>Deactive</option>
                  </Form.Control>
                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button onClick={this.handleSubmit} className="btn btn-primary">
              {loader && <i class="fas fa-spinner fa-spin"></i>}Update currency
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CurrencyUpdateModal;
