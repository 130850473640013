const nav = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/accounts",
    name: "Accounts",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/trades",
    name: "Trades",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/bankaccounts",
    name: "Bank Accounts",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/partners",
    name: "Partners",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/currency",
    name: "Currency",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/country",
    name: "Country",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/source-of-fund",
    name: "Source of Funds",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/emailtemplates",
    name: "Email Templates",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/priceCNVlist",
    name: "Price Conversion",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/site-setting",
    name: "Site Setting Page",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  {
    path: "/bankaccount-setting",
    name: "Bank Account Setting",
    exact: true,
    sidemenu: true,
    type: "private",
  },
  /* -------------------------------------------- */
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //     path: "/sub-admin",
  //     name: 'SubAdmin',
  //     exact: true,
  //     sidemenu: true,
  //     type: "private"
  // },
  // {
  //   path: "/users",
  //   name: "User",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/notification",
  //   name: "Notification History",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/Pairmanagement",
  //   name: "Pair Management",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // // {
  // //     path: "/Tradingbot",
  // //     name: 'Tradingbot',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/Staking",
  // //     name: 'Staking',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/Stake-Settlement",
  // //     name: 'Stake Settlement',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/Stake-History",
  // //     name: 'Stake History',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // {
  //   path: "/site-setting",
  //   name: "Site Setting Page",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/cms",
  //   name: "Cms",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/language",
  //   name: 'Language',
  //   exact: true,
  //   sidemenu: true,
  //   type: "private"
  // },
  // {
  //   path: "/contactus",
  //   name: "Contactus",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/promo-code",
  //   name: "Bonus Settings",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },


  // {
  //   path: "/faq-category",
  //   name: "Faq Category",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/faq",
  //   name: "Faq Page",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/emailtemplates",
  //   name: "Email Templates",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/perpetual",
  //   name: 'Perpetual',
  //   exact: true,
  //   sidemenu: true,
  //   type: 'private'
  // },
  // {
  //   path: "/anouncement",
  //   name: "Anouncement",
  //   exact: true,
  //   sidemenu: false,
  //   type: "private",
  // },
  // {
  //   path: "/support-category",
  //   name: "Support Category",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/support",
  //   name: "Support",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // // {
  // //     path: "/support_reply/:id",
  // //     name: 'SupportReply',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // {
  //   path: "/security",
  //   name: "GoogleAuth",
  //   exact: true,
  //   sidemenu: false,
  //   type: "private",
  // },

  // {
  //   path: "/kyc",
  //   name: "Kyc",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/currency",
  //   name: "Currency",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },

  // {
  //   path: "/profile",
  //   name: "Profile",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/profit-management",
  //   name: "Profit Management",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // // {
  // //     path: "/settings",
  // //     name: 'Settings',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // {
  //   path: "/changepassword",
  //   name: "Change Password",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/orderhistory",
  //   name: "Order History",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/tradehistory",
  //   name: "Trade History",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/perpetual-Trade-History",
  //   name: 'perpetual Trade History',
  //   exact: true,
  //   sidemenu: true,
  //   type: "private"
  // },
  // {
  //   path: "/perpetual-Order-History",
  //   name: 'Perpetual Order History',
  //   exact: true,
  //   sidemenu: true,
  //   type: "private"
  // },
  // {
  //   path: "/perpetual-funding-rate",
  //   name: 'Perpetual Funding Rate',
  //   exact: true,
  //   sidemenu: true,
  //   type: "private"
  // },
  // {
  //   path: "/withdraw",
  //   name: "Withdraw",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/deposit",
  //   name: "Deposit",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },

  // // {
  // //     path: "/launchpad",
  // //     name: 'Launchpad',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/token-purchase",
  // //     name: 'TokenPurchase',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/kyc",
  // //     name: 'Kyc',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },

  // {
  //   path: "/newsletter",
  //   name: "Newsletter",
  //   exact: true,
  //   sidemenu: true,
  // },
  // // {
  // //     path: "/closedpositions",
  // //     name: 'Closedpositions',
  // //     exact: true,
  // //     sidemenu: true,
  // // },
  // // {
  // //     path: "/feesettings",
  // //     name: 'fees settings',
  // //     exact: true,
  // //     sidemenu: true,
  // // },
  // // {
  // //     path: "/liquidated",
  // //     name: 'Liquidated',
  // //     exact: true,
  // //     sidemenu: true,
  // // },
  // // {
  // //     path: "/p2p-pair",
  // //     name: 'P2pPair',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/p2p-order",
  // //     name: 'P2pOrder',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/p2p-ordrView/:orderId",
  // //     name: 'P2pOrderView',
  // //     exact: true,
  // //     sidemenu: false,
  // //     type: "private"
  // // },
  // // {
  // //     path: "/p2p-dispute",
  // //     name: 'P2pDispute',
  // //     exact: true,
  // //     sidemenu: true,
  // //     type: "private"
  // // },
  // {
  //   path: "/kycupdate/:id",
  //   name: "kyc Update",
  //   exact: true,
  //   sidemenu: false,
  // },
  // {
  //   path: "/future-pair",
  //   name: "Future Pair",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/future-order",
  //   name: "Future Order",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/future-trade",
  //   name: "Future Trade",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/feesOwnToken",
  //   name: "Referal Settings",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/referalCommission",
  //   name: "Referral Commission",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },

  // {
  //   path: "/referraltier",
  //   name: "Referral Tier",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },

  // {
  //   path: "/liquidationUser",
  //   name: "Liquidation User",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/copy-future-trader",
  //   name: "Copy Trader",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/top-traders",
  //   name: "Copy Trade: Top Traders",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/copy-trade-settings",
  //   name: "Copy Trader:Setting",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // // {
  // //   path: "/passbook",
  // //   name: "Pass Book",
  // //   exact: true,
  // //   sidemenu: true,
  // //   type: "private",
  // // },
  // {
  //   path: "/sms-log",
  //   name: "SMS Log",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // },
  // {
  //   path: "/wallet-monitor",
  //   name: "Wallet Monitor",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  // }
]

export default nav;
